import api from '@api'
import GroupBy from 'lodash/groupBy'
import UniqBy from 'lodash/uniqBy'
import SortBy from 'lodash/sortBy'
import {
  transformRequiredNoteRules,
  transformDialogRules,
  transformWorklogRules,
} from '@state/modules/custom-rules'

import { getRootTranslator } from '@utils/get-module-translator'

const __rootT = getRootTranslator()

export const transformAssetCustomRules = (customrule) => {
  return {
    updateHardwareComponent: customrule.updateHardwareComponent,
    updateSoftwareComponent: customrule.updateSoftwareComponent,
    updateUser: customrule.updateUser,
    askApprovalForHardwareAsset: customrule.askApprovalForHardwareAsset,
    askApprovalForNonITAsset: customrule.askApprovalForNonITAsset,
  }
}

const transformAssetCustomRulesForServer = (customrule) => {
  return {
    updateHardwareComponent: customrule.updateHardwareComponent,
    updateSoftwareComponent: customrule.updateSoftwareComponent,
    updateUser: customrule.updateUser,
    askApprovalForHardwareAsset: customrule.askApprovalForHardwareAsset,
    askApprovalForNonITAsset: customrule.askApprovalForNonITAsset,
  }
}

const transformClosureRulesForServer = (rule) => {
  const customfieldRuleSet = []
  const mandatoryPropKeys = []
  rule.items.forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      customfieldRuleSet.push(paramName)
    } else {
      mandatoryPropKeys.push(paramName)
    }
  })
  return {
    refId: rule.parentId,
    mandatoryPropKeys,
    customfieldRuleSet,
  }
}

const transformClosureRules = (rule) => {
  return {
    id: rule.id,
    parentId: rule.refId,
    items: (rule.mandatoryPropKeys || []).concat(rule.customfieldRuleSet || []),
  }
}

const transformRequiredNoteRulesForServer = (rule) => {
  const customfieldForNotes = []
  const propKeysForNotes = []
  rule.items.forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      customfieldForNotes.push(paramName)
    } else {
      propKeysForNotes.push(paramName)
    }
  })

  const ruleInfo = Object.entries(rule.ruleInfo).map(([key, value]) => ({
    propKey: key,
    refIds: rule.items.indexOf(key) >= 0 ? value : [],
  }))

  return {
    refId: rule.parentId,
    customfieldForNotes,
    propKeysForNotes,
    rulePropInfo: ruleInfo,
  }
}

const transformDialogRulesForServer = (rule) => {
  const customfieldForDialog = []
  const propKeysForDialog = []
  rule.items.forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      customfieldForDialog.push(paramName)
    } else {
      propKeysForDialog.push(paramName)
    }
  })

  const ruleInfo = Object.entries(rule.ruleInfo).map(([key, value]) => ({
    propKey: key,
    refIds: rule.items.indexOf(key) >= 0 ? value : [],
  }))

  return {
    refId: rule.parentId,
    customfieldForDialog,
    propKeysForDialog,
    rulePropInfo: ruleInfo,
  }
}
export function assetCustomRulesApi() {
  return api.get('/asset/customrule').then((data) => {
    return transformAssetCustomRules(data)
  })
}

export function updateAssetCustomRulesApi(data) {
  return api
    .patch('/asset/customrule', transformAssetCustomRulesForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootT('custom_rules'),
      }),
    })
    .then(transformAssetCustomRules)
}

export function closureRulesApi(moduleName, status, parentId) {
  return api
    .get(`/${moduleName}/closerrule/${status}`, {
      params: {
        refId: parentId,
      },
    })
    .then((data) => {
      return transformClosureRules(data)
    })
}

export function updateClosureRulesApi(moduleName, data) {
  return api
    .patch(
      `/${moduleName}/closerrule/${data.id}`,
      transformClosureRulesForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootT('custom_rules'),
        }),
      }
    )
    .then(transformClosureRules)
}

export function closureRuleSupportedDataApi(moduleName, status, parentId) {
  return api
    .get(`/${moduleName}/closerrule/supportedData/${status}`, {
      params: { refId: parentId },
    })
    .then((data) => {
      const allData = data.map((d) => ({
        group: d.groupName,
        name: d.name,
        order: d.groupOrder,
        paramName: d.key,
        isCustomField: d.customField,
      }))
      const finalData = {}
      const groupdData = GroupBy(allData, 'group')
      const groupMap = SortBy(
        UniqBy(
          allData.map((d) => ({
            groupName: d.group,
            order: d.order,
          })),
          'groupName'
        ),
        'order'
      )
      groupMap.forEach((g) => {
        finalData[g.groupName] = groupdData[g.groupName]
      })
      return finalData
    })
}

export function updateNoteRulesApi(moduleName, data) {
  return api
    .patch(
      `/${moduleName}/requirednoterule/${data.id}`,
      transformRequiredNoteRulesForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootT('custom_rules'),
        }),
      }
    )
    .then(transformRequiredNoteRules)
}

export function updateDialogRulesApi(moduleName, data) {
  return api
    .patch(
      `/${moduleName}/showdialogrule/${data.id}`,
      transformDialogRulesForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootT('custom_rules'),
        }),
      }
    )
    .then(transformDialogRules)
}

export function getServiceCatalogCustomRulesInfoApi(data) {
  return api
    .post(`/service_catalog/customrule/info`, data, {
      notify: false,
    })
    .then((response) => {
      const transformData = {}
      Object.keys(response || {}).map((key) => {
        transformData[key] = {
          noteRules:
            (
              transformRequiredNoteRules(response[key].requiredNoteRuleRest) ||
              {}
            ).items || [],
          dialogRules:
            (transformDialogRules(response[key].showDialogRuleRest) || {})
              .items || [],
          qualifiedDialogRules:
            (transformDialogRules(response[key].showDialogRuleRest) || {})
              .ruleInfo || {},
          worklogAccessRules:
            (transformWorklogRules(response[key].workLogRuleRest) || {}).item ||
            '',
          qualifiedRequiredNoteRules:
            (
              transformRequiredNoteRules(response[key].requiredNoteRuleRest) ||
              {}
            ).ruleInfo || {},
        }
      })
      return transformData
    })
}

export function getWorklogRuleSupportedDataApi(moduleName, rule) {
  return api
    .get(`/${moduleName}/closerrule/supportedData/${rule}`)
    .then((data) => {
      const allData = data.map((d) => ({
        group: d.groupName,
        name: d.name,
        order: d.groupOrder,
        paramName: d.key,
        isCustomField: d.customField,
      }))
      const finalData = {}
      const groupdData = GroupBy(allData, 'group')
      const groupMap = SortBy(
        UniqBy(
          allData.map((d) => ({
            groupName: d.group,
            order: d.order,
          })),
          'groupName'
        ),
        'order'
      )
      groupMap.forEach((g) => {
        finalData[g.groupName] = groupdData[g.groupName]
      })
      return finalData
    })
}

export function getWorklogRuleApi(moduleName, parentId) {
  return api
    .get(`/${moduleName}/worklogrule`, {
      params: {
        refId: parentId,
      },
    })
    .then((data) => {
      return transformWorklogRules(data)
    })
}

export function updateWorklogRulesApi(moduleName, data) {
  return api
    .patch(
      `/${moduleName}/worklogrule/${data.id}`,
      transformWorklogRulesForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootT('custom_rules'),
        }),
      }
    )
    .then(transformWorklogRules)
}

const transformWorklogRulesForServer = (rule) => {
  const managedKey = rule.item
  return {
    refId: rule.parentId,
    managedKey,
  }
}
