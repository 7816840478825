import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const computerSystemComponentFieldMap = () => ({
  computer_system_component: {
    canCreate: true,
    maxCreation: 1,
    title: __mtc('computer_system_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'computerSystemName',
        name: `${__mtc('name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'domainName',
        name: `${__mtc('domain_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'modelName',
        name: `${__mtc('model_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'systemType',
        name: `${__mtc('system_type')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'pcSystemType',
        name: `${__mtc('pc_system_type')}`,
        inputType: 'dropdown',
        contextOptionKey: 'pcSystemTypeOptions',
      },
      {
        key: generateId(),
        paramName: 'uuid',
        name: `${__mtc('uuid')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'bootUpStatus',
        name: `${__mtc('boot_up_status')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'numberOfLogicalProcessors',
        name: `${__mtc('number_of_logical_processors')}`,
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'numberOfProcessors',
        name: `${__mtc('number_of_processors')}`,
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'partOfDomain',
        name: `${__mtc('part_of_domain')}`,
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'userName',
        name: __mtc('user_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'lastRebootTime',
        name: `${__mtc('last_reboot_time')}`,
        inputType: 'dateTime',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
